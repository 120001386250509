import React from 'react'
import {
    Switch,
    Route,
    Link,
    Routes,
    BrowserRouter
  } from "react-router-dom";

import Evento from '../pages/Evento';
import Menu from '../layouts/Menu';
import Resultado from '../pages/Resultado';
import Home from '../pages/Home';

export const AppRoutes = () => {
  return (
    <BrowserRouter>
        <Menu
        />
        <div className='container pt-3 pb-3'>
          <Routes>
              <Route path="/" element={<Home/>}>
              </Route>
              <Route path="/evento" element={<Evento/>}/>
              <Route path="/resultado-pago" element={<Resultado/>}/>
          </Routes>
        </div>
          
    </BrowserRouter>
  )
}
