import React, { useEffect, useState } from 'react'
import Payment from '../../assets/img/payment-method.png'
import ErrorPago from './ErrorPago';
const ResultadoPago = ({
    session
}) => {
   const [voucher, setvoucher] = useState(null);

   useEffect(() => {
    if (session!==null) {
        if (session.status===0) {
            setvoucher(JSON.parse(session.tbklog.json));
           
            console.log(JSON.parse(session.tbklog.json));
        }
    }
   },[session])
  return  session=== null ? <></> : session.status===0 ? (
    <div className='row'>
        <div className='col-md-12 mt-5 text-center'>
            <img src={Payment} width={100}></img>
            <h1>Pago realizado con éxito</h1>
            <p>Hemos recibido tu pago con éxito. En unos minutos recibirás un correo electrónico con el comprobante de tu
                compra.
            </p>
            <p>A continuación el detalle de tu compra:</p><br/>
            <p>Tienes dudas consultas o algun problema,  escríbenos a  <b>eventos@penaflor.cl</b></p> 
        </div>
        <div className="col-md-6">
            <div className='row'>
                <div className='col-md-12 text-center'>
                    <h3>Datos de la compra</h3>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 text-center'>
                    {
                        voucher!==null ? 
                        <table className='table'>
                            <tbody>
                            <tr>
                                <td>Monto total</td>
                                <td><b>{voucher.amount}</b></td>
                            </tr>
                          
                            <tr>
                                <td>Orden de compra</td>
                                <td><b>{voucher.buy_order}</b></td>
                            </tr>
                            <tr>
                                <td>Fecha y hora de la compra</td>
                                <td><b>{voucher.transaction_date}</b></td>
                            </tr>
                            <tr>
                                <td>Tarjeta</td>
                                <td><b>{voucher.card_detail.card_number}</b></td>
                            </tr>
                            <tr>
                                <td>Código de autorización transbank</td>
                                <td><b>{voucher.authorization_code}</b></td>
                            </tr>
                            </tbody>
                        </table>
                        :<>Cargando...</>
                    }
                </div>
            </div>
        </div>
        <div className="col-md-6">
            <div className='row'>
                <div className='col-md-12 text-center'>
                    <h3>Tus entradas</h3>
                </div>
                
                {
                    session.entradas.map((entrada,index)=>{
                        return (
                            <>
                                <a 
                                href={entrada.link}
                                key={entrada.id}
                                className='btn btn-outline-dark mb-3 dotted' 
                                download={true}>Descargar entrada - {entrada.producto} <i className='fa fa-download'></i></a>
                                
                            </>
                            
                        )
                    })
                }
            </div>
        </div>
    </div>
  ) :
    <ErrorPago session_id={session.session_id} ></ErrorPago>
}

export default ResultadoPago
