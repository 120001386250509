import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import {
    Switch,
    Route,
    Link,
    Routes,
    BrowserRouter
  } from "react-router-dom";
import Logo from '../assets/img/logo.png';

const Menu = () => {
    return (
    
            <nav className="navbar navbar-expand-sm navbar-light bg-white">
                <div 
                    className='container'
                    >
                
                    <Link className="navbar-brand" to="/">
                        <img src={Logo} width={100} className="mr-3"/>
                    </Link>
                    <button className="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    
                    <div className="collapse navbar-collapse" id="collapsibleNavId">
                        <ul className="navbar-nav ml-auto mt-2 my-lg-0">
                            {/*}
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Carrito</a>
                                </li>
                            {*/}
                        </ul>
                    </div>
                    
                </div>
            </nav>
    
    )
}

export default Menu