import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

async function cargarEvento(){
 
    var requestOptions = {
      method: 'get'
    };
  
    const respuesta=await fetch("https://eventosapi.penaflor.cl/api/v1/events", requestOptions)
      .then((response)=>{
        return response.json();
      })
      .then((response)=>{
        return response;
      })
      .catch(error => console.log('error', error));
    return respuesta;
}

const Home = () => {
    const [cargando, setcargando] = useState(true);
    const [eventos, seteventos] = useState(null);
    useEffect(() => {
      cargarEvento().then((data) => {
        seteventos(data);
        setcargando(false);
      });
    }, [])
    
    
    return cargando && eventos===null ?
    <div className='loading'></div>
    :
    <div className='row'>
     {eventos.map((evento,index) => {
            return <div className='col-md-4 mb-4'>
                <div
                  className="meeta-event-schedule-banner"
                  style={{ 
                    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://eventosapi.penaflor.cl/"+evento.imagen+")" }}
                >
                  <div className="meeta-section-title">
                    <h4 className="sub-title text-pink">Evento</h4>
                    <h2 className="main-title text-white">
                      {evento !==null ? evento.titulo : 'Cargando...'}
                    </h2>
                
                   
                  </div>
                  {
                    /*
                  }
                  <a href="schedule.html" className="btn btn-3 btn-primary">
                    Descargar Información <i className="fa fa-download" />
                  </a>
                  {
                    */
                  }
                   <Link to={`evento?q=${evento.slug}`} className="btn-bottom btn btn-3 btn-primary">
                        Comprar Tickets <i className='fa fa-check'></i>
                    </Link>
                </div>
                </div>
            
     }) }
     </div>
    
   
}

export default Home